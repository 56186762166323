<template>
  <div class="container is-fluid no-margins-container">
    <div
      class="chosen-background"
      :style="{backgroundImage: `url(${requireFromAssetsIfLocalSrc(selectedBackgroundImageURL)}`}">
    </div>

    <div class="section choose-background-section">
      <div class="background-options-container">
        <video
          class="bg-instructions-video"
          ref="bg_instructions"
          :src="content.subject_video"
          autoplay
          loop />

        <div class="columns">
          <div class="column"></div>
          <CardTitle class="choose-background" :text="content.subject_title" />
          <div class="column"></div>
        </div>
        <div class="columns backgrounds-container">
          <BackgroundChoice
            v-for="background in backgrounds"
            v-bind:key="background.locationName"
            :backgroundImageURL="background.backgroundImageURL"
            :locationName="background.locationName"
            :selected="background.selected"
            @click.native="changeBackground(background)" />
        </div>
        <router-link :to="{name: 'record-video'}">
          <o-button type="primary" class="o-btn--primary" :label="content.subject_button_select_label" ></o-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CONST from '@/utils/Constants'
import ApiHelper from "@/helpers/ApiHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
// @ is an alias to /src
import CardTitle from "@/components/CardTitle.vue";
import BackgroundChoice from "@/components/BackgroundChoice.vue";
import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: "choose-background",
  components: {
    BackgroundChoice,
    'o-button': oButton,
    CardTitle
  },
  data: function () {
    return {
      selectedBackgroundImageURL: 'img/museum.png',
    }
  },
  computed: {
    content() {
      return this.$store.getters.content;
    },
    backgrounds() {
      return [
        {
            locationName: this.getEmsDataPropertyText('background_1_title_page3'),
            selected: true,
            backgroundImageURL: this.getEmsDataPropertyText('background_1_page3')
          },
          {
            locationName: this.getEmsDataPropertyText('background_2_title_page3'),
            selected: false,
            backgroundImageURL: this.getEmsDataPropertyText('background_2_page3')
          },
          {
            locationName: this.getEmsDataPropertyText('background_3_title_page3'),
            selected: false,
            backgroundImageURL: this.getEmsDataPropertyText('background_3_page3')
          }
      ]
    }
  },
  methods: {
    changeBackground: function (background) {
      this.backgrounds.forEach(dataBackground => {
        // setting selected background to true and everything else to false
        dataBackground.selected = dataBackground.locationName == background.locationName;
      });

      this.selectedBackgroundImageURL = background.backgroundImageURL;

      let data = {
        "message": this.selectedBackgroundImageURL,
        "channel": CONST.PUSHER.CHANNEL,
        "event-name": CONST.EVENT.BACKGROUND.SELECTED,
      }

      ApiHelper.post(CONST.API.BACKGROUND.SELECT, data)
      .catch(error => {
          ErrorHelper.catch(error, this);
      });
    }
  }
};
</script>

<style lang="scss">
.instructions-card-title-container {
  position: relative;
  top: 60px !important; // change this to adjust title position
}
</style>

<style scoped>

.choose-background-section {
  height: 100vh;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
}

.chosen-background {
  height: 47vh;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  background-image: url('../assets/img/museum.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.background-options-container {
  position: absolute;
  bottom: 64px;
  left: 0px;
  right: 0px;
}

.backgrounds-container {
  margin-left: 128px;
  margin-right: 128px;
}

.background-subtitle {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: "Colfax-Medium";
}

.museum {
  height: 256px;
  background-color: red;
}

.no-margins-container {
  margin: 0px;
}

.container {
  background-color: white;
}

.bg-instructions-video {
  width: 40%;
  position: relative;
  bottom: 21px;
}
</style>