<template>
    <div class="column is-4">
        <div 
            class="background-choice-image"
            :class="selected ? null : 'background-deselected'"
            :style="{backgroundImage: `url(${requireFromAssetsIfLocalSrc(backgroundImageURL)})`}">

            <div 
                class="selected-dot"
                v-show="selected"></div>
        </div>
        <p class="background-subtitle">{{ locationName.toUpperCase() }}</p>
    </div>
</template>

<script scoped>

export default {
  name: 'CardTitle',
  props: {
      selected: {
          type: Boolean,
          required: true
      },
      locationName: {
          type: String,
          required: false
      },
      backgroundImageURL: {
          type: String,
          required: true
      },
  }
}

</script>

<style scoped>

.background-subtitle {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 24px;
    font-family: "Colfax-Bold";
}

.background-choice-image {
    height: 256px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.background-deselected {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.selected-dot {
    height: 24px;
    width: 24px;
    background-color: #97D700;
    border-radius: 50%;
    position: absolute;
    right: 16px;
    top: 16px;
    border: 5px solid white;
}

</style>